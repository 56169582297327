.footer-container{
    display: flex;
    align-content: center;
    align-items: center;
    width: 100vw;
    height: 10vh;
    text-align: center;
    justify-content: center;
    border-top: 1px solid black;
    bottom: 0;
    padding-top: 1em;
    position: relative;
}

.footer-container a{
    color: #7464c1;
}

.footer-tab-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    align-self: center;
    width: calc(65vw + 55px);
    height: auto;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 20px;
    justify-content: center
}

.footer-container span{
    color: grey;
    margin-right: 15vw;
    margin-left: 15vw;
}

link{
    color: grey;
    cursor: pointer;
}

.about-container{
    height: inherit;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;

}

.about-container h2{
    color:  #7464c1;
    padding-top: 20px;
}

.about-container p{
    font-size: medium;
    line-height: 32px;
}

.about-container p a{
    color:  #7464c1;
}

.contact-container{
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;

}

.contact-container p{
    text-align: center;
    font-size: x-large;
}

.footer-title{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
}

.footer-title span{
    font-size: 3em;
    font-weight: bolder;
}

.privacy-policy-content p{
    line-height: 32px;
}

.query-result{
    margin: 100px 20px 200px 20px;
    text-align: center;
    line-height: 3.5em;
}

@media screen and (max-device-width: 600px) {
    .footer-container {
        display: flex;
        align-content: center;
        align-items: center;
        width: 100vw;
        height: 10vh;
        text-align: center;
        justify-content: center;
        border-top: 1px solid black;
        bottom: 0;
        padding-top: 1em;
        position: relative;
    }
    .footer-container span{
        color: grey;
        margin-right: 5vw;
        margin-left: 5vw;
    }
    .about-container p {
        font-size: larger;
        line-height: 32px;
    }

    .footer-title span{
        font-size: 2em;
        font-weight: bolder;
        text-align: center;
    }

    .about-container{
        height: inherit;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;

    }

    .contact-container{
        height: 55vh;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;

    }

    .contact-container p{
        font-size: large;
    }


}
