.category-title{
    width:65vw;
    background-color: #ffd964;
    color: black;
    padding: 15px 20px 15px 20px;
    font-weight: bold;
    margin: 10px auto;

}

@media (max-device-width: 600px) {
    .category-title{
        width:80vw;
        background-color: #ffd964;
        color: black;
        padding: 15px 20px 15px 20px;
        font-weight: bold;
        margin: 10px auto;

    }
}